import React from "react"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import WaveDivider from "./svgs/WaveDivider"

export const HeroTitleDescription = ({
  title = "Title!",
  text = "Some text here",
  className,
  classNameText = "",
}) => {
  return (
    <div
      className={`flex flex-col justify-center items-end text-right max-w-3xl lg:mr-40 mx-5 my-20 ${className}`}
    >
      <span className="flex flex-col w-full items-center justify-center md:justify-start md:flex-row-reverse">
        <h1 className=" font-bold">{title}</h1>
        <WaveDivider className="w-32 fill-secondary mr-0 mt-5 md:mt-0 md:mr-5" />
      </span>
      <p
        className={`mt-5 text-2xl md:text-3xl text-center md:text-right w-full ${classNameText}`}
      >
        {text}
      </p>
    </div>
  )
}

const Hero = ({ props, children }) => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      defaultHeroImage: file(relativePath: { eq: "indexBackground.jpg" }) {
        id
        childImageSharp {
          gatsbyImageData(width: 2000, placeholder: BLURRED)
        }
      }
    }
  `)

  const imageData = getImage(data.defaultHeroImage)
  const bgImage = convertToBgImage(imageData)

  const heroFluid = props?.heroFluid || bgImage.fluid

  // determines how the content of the Hero section is displayed
  const contentPosition = props?.gridPosition || `justify-center md:justify-end`

  return (
    <BackgroundImage
      className={`grid ${contentPosition} min-h-xl bg-black bg-opacity-40 ${props?.className}  `}
      fluid={heroFluid}
      preserveStackingContext
    >
      {children}
    </BackgroundImage>
  )
}

export default Hero
